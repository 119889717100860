import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    baseURL: 'https://dsbgeo.jaktraffic.my.id',// Production URL
    // baseURL: 'http://192.168.60.54:4480', //Development URL
    
  };
  
  const urlSlice = createSlice({
    name: 'url',
    initialState,
    reducers: {
      setBaseURL: (state, action) => {
        state.baseURL = action.payload;
      },
    },
  });
  
  export const { setBaseURL } = urlSlice.actions;
  export const selectBaseURL = (state) => state.url.baseURL;
  
  export default urlSlice.reducer;