import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import axios from 'axios';
import '../ViewAllUsers.css';
import { selectBaseURL } from '../store/url/urlSlice';
import { useSelector } from 'react-redux';

const HomePage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const baseURL = useSelector(selectBaseURL); 
  const Nrk = localStorage.getItem('NRKorNIP');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${baseURL}/geoapi/user/recapData`, {
          NRKorNIP: Nrk,
        });
        const usersData = response.data.satuan;

        const usersArray = Object.entries(usersData).map(([unit, data], index) => ({
          id: `temp-${index}`, 
          satuan: unit,
          ...data,
        }));        

        setUsers(usersArray); 
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data');
        setLoading(false);
      }
    };

    fetchData();
  }, [Nrk]);

  if (loading) {
    return <Layout>Loading...</Layout>;
  }

  if (error) {
    return <Layout>{error}</Layout>;
  }

  return (
    <Layout>
      <div className="p-4">
        <h2 className="text-2xl font-bold dark:text-white text-center mb-4">Selamat Datang di Geomap</h2>
        <br />
        
        <div className="overflow-x-auto">
          <div className="max-h-96 overflow-y-auto">
            {users.length === 0 ? (
              <div className="text-center dark:text-white">Data not found</div>
            ) : (
              <table className="table-auto w-full border-collapse border-2">
                <thead className="sticky top-0 bg-gray-200 text-gray-800">
                  <tr>
                    <th className="border p-2 font-semibold">No</th>
                    <th className="border p-2 font-semibold">UKPD</th>
                    <th className="border p-2 font-semibold">Total Anggota</th>
                    <th className="border p-2 font-semibold">Jumlah Petugas Aktif</th>
                    <th className="border p-2 font-semibold">Jumlah Petugas Suspen</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, index) => (
                    <tr key={user.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      <td className="border p-2 dark:text-white">{index + 1}</td> {/* Menampilkan nomor urut */}
                      <td className="border p-2 dark:text-white">{user.satuan}</td>
                      <td className="border p-2 dark:text-white">{user.Total_Anggota}</td>
                      <td className="border p-2 dark:text-white">{user.Petugas_Aktif}</td>
                      <td className="border p-2 dark:text-white">{user.Petugas_Suspen}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
