import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CgEye, CgTrash } from 'react-icons/cg';
import { FaCheck, FaTimes } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { selectBaseURL } from '../store/url/urlSlice';
import PropTypes from 'prop-types';

const TableUserlist = ({ userID }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    nama: '',
    jenis: '',
    satuan: '',
    role: ''
  });
  const [filterOptions, setFilterOptions] = useState({
    jenis: [],
    satuan: [],
    role: []
  });
  const navigate = useNavigate();
  const baseURL = useSelector(selectBaseURL); 

  const handleViewUser = (userID) => {
    navigate(`/profile/${userID}`);
  };

  TableUserlist.propTypes = {
    userID: PropTypes.string.isRequired,  
  };
  
  const handleDeleteUser = async (userID) => {
    Swal.fire({
      title: 'Konfirmasi Menghapus Profile',
      text: 'Anda yakin akan menghapus profile?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Hapus',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${baseURL}/geoapi/user/delete/${userID}`);
          localStorage.clear();
          Swal.fire({
            title: 'Profile Berhasil Dihapus',
            text: 'Anda telah menghapus profile',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.reload();
          });
        } catch (error) {
          // console.error('Error deleting profile:', error);
        }
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}/geoapi/user/viewu/${userID}`);
        const usersData = response.data;

        const uniqueJenis = [...new Set(usersData.map(user => user.Jenis))];
        const uniqueSatuan = [...new Set(usersData.map(user => user.Satuan))];
        const uniqueRole = [...new Set(usersData.map(user => user.Role))];

        setUsers(usersData);
        setFilterOptions({
          jenis: uniqueJenis,
          satuan: uniqueSatuan,
          role: uniqueRole
        });
        setLoading(false);
      } catch (error) {
        // console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userID, baseURL]); // Tambahkan baseURL ke dependency array

  // Filter dan render komponen tetap sama
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const filteredUsers = users.filter(user => {
    return (
      (filters.nama === '' || user.Nama.toLowerCase().includes(filters.nama.toLowerCase())) &&
      (filters.jenis === '' || user.Jenis === filters.jenis) &&
      (filters.satuan === '' || user.Satuan === filters.satuan) &&
      (filters.role === '' || user.Role === filters.role)
    );
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="filter-container flex items-center space-x-4 mb-4">
        <input
          type="text"
          name="nama"
          value={filters.nama}
          onChange={handleFilterChange}
          placeholder="Cari Nama"
          className="filter-select p-2 border rounded"
        />
        <select name="jenis" value={filters.jenis} onChange={handleFilterChange} className="filter-select p-2 border rounded">
          <option value="">All Jenis</option>
          {filterOptions.jenis.map(jenis => (
            <option key={jenis} value={jenis}>{jenis}</option>
          ))}
        </select>
        <select name="satuan" value={filters.satuan} onChange={handleFilterChange} className="filter-select p-2 border rounded">
          <option value="">All Satuan</option>
          {filterOptions.satuan.map(satuan => (
            <option key={satuan} value={satuan}>{satuan}</option>
          ))}
        </select>
        <select name="role" value={filters.role} onChange={handleFilterChange} className="filter-select p-2 border rounded">
          <option value="">All Roles</option>
          {filterOptions.role.map(role => (
            <option key={role} value={role}>{role}</option>
          ))}
        </select>
      </div>

      <div className="table-container" style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <table className="table-auto w-full border-collapse border-2">
          <thead className="sticky top-0 bg-gray-200 text-gray-800">
            <tr>
              <th className="border p-2 font-semibold">No</th>
              <th className="border p-2 font-semibold">UserID</th>
              <th className="border p-2 font-semibold">NRK/NIP</th>
              <th className="border p-2 font-semibold">Nama</th>
              <th className="border p-2 font-semibold">Email</th>
              <th className="border p-2 font-semibold">Jenis</th>
              <th className="border p-2 font-semibold">Satuan</th>
              <th className="border p-2 font-semibold">Role</th>
              <th className="border p-2 font-semibold">Status</th>
              <th className="border p-2 font-semibold">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, u) => (
              <tr key={u} className={u % 2 === 0 ? 'bg-white-100' : ''}>
                <td className="border p-2">{u + 1}</td>
                <td className="border p-2">{user.userID}</td>
                <td className="border p-2">{user.NRKorNIP}</td>
                <td className="border p-2">{user.Nama}</td>
                <td className="border p-2">{user.Email}</td>
                <td className="border p-2">{user.Jenis}</td>
                <td className="border p-2">{user.Satuan}</td>
                <td className="border p-2">{user.Role}</td>
                <td className="border p-2">
                  {user.isActive ? <FaCheck style={{ color: 'green' }} /> : <FaTimes style={{ color: 'red' }} />}
                </td>
                <td className="border p-2">
                  <button onClick={() => handleViewUser(user.userID)}>
                    <CgEye />
                  </button>
                  <button onClick={() => handleDeleteUser(user.userID)}>
                    <CgTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p>Total Users: {filteredUsers.length}</p>
    </div>
  );
};

export default TableUserlist;
