import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from 'axios';
import Swal from 'sweetalert2'; 
import logoImage from '../assets/images/logo.png';
import { useSelector } from 'react-redux';
import { selectBaseURL } from '../store/url/urlSlice';


const Login = () => {
  const [password, setPassword] = useState('');
  const baseURL = useSelector(selectBaseURL); 
  const userIDRef = useRef();
  const [message,] = useState('');
  const handleLogin = async (e) => {
    e.preventDefault();
    const loginData = {
      NRKorNIP: userIDRef.current.value,
      Password: password,
    };

    try {
      const response = await axios.post(`${baseURL}/geoapi/user/login`, loginData);

      // console.log(response.data);

      if (response.status === 200) {
        if (response.data.Role === "Petugas") {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Petugas tidak diizinkan untuk login.',
          });
          return; 
        }
        const token = response.data.token;
        const IDs = response.data.userID;
        const Satuan = response.data.Satuan;
        const Role = response.data.Role;
        localStorage.setItem('token', token);
        localStorage.setItem('NRKorNIP', loginData.NRKorNIP);
        localStorage.setItem('IDs', IDs);
        localStorage.setItem('Satuan', Satuan);
        localStorage.setItem('Role', Role);

        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'You have successfully logged in.',
          confirmButtonText: 'OK',
          confirmButtonColor: '#0d6efd',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/HomePage'; // Arahkan ke halaman HomePage setelah OK ditekan
          }
        });
        
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while logging in.',
        });
      }
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.response.data.message,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while logging in.',
        });
      }
    }
  };


  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 via-lightblue-500 to-gray-400">
      <div className="max-w-md w-full mx-4 mt-8 bg-white p-8 rounded shadow-lg">

        <img
          src={logoImage}
          alt="Logo"
          className="mx-auto mb-4"
          style={{ width: '100px', height: 'auto' }}
        />
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-2xl font-bold mb-1 text-center"
          style={{ color: '#14315D' }}
        >
          GEOMAP
        </motion.h2>
        <p className="text-center text-sm  font-bold mt-0" style={{ fontSize: '15px', color: '#14315D' }}> {/* Remove bottom margin */}
          Dinas Perhubungan <br /> Provinsi DKI Jakarta
        </p>
        {message && <p className="mb-4 text-center text-red-500">{message}</p>}
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label htmlFor="userID" className="block text-sm font-medium text-gray-700">
              NIP/NRK
            </label>
            <input
              type="text"
              id="userID"
              className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Masukkan NIP/NRK Anda"
              ref={userIDRef}

              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Masukkan password Anda"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="flex justify-center"
          >
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded font-medium"
            >
              Log in
            </button>
          </motion.div>
          <div className="mt-2 text-center">
            <Link to="/reset-password" className="text-blue-500 text-sm animate-bounce">
              Reset Password
            </Link>
            <p>
              {/* <Link to="/Register" className="text-blue-500 text-sm animate-bounce">
                Belum Ada Akun?
              </Link> */}
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
