import React, { useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { FaArrowLeft } from 'react-icons/fa'; // Import ikon Font Awesome
import { selectBaseURL } from '../store/url/urlSlice';
import { useSelector } from 'react-redux';

const ResetPassword = () => {
  const [userID, setUserID] = useState('');
  const [uniqueCode, setUniqueCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const baseURL = useSelector(selectBaseURL); 
  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const resetEndpoint = `${baseURL}/geoapi/usermbl/res`;
      const requestBody = {
        userID,
        UniqueCode: uniqueCode,
        Password: newPassword,
      };

      const response = await axios.put(resetEndpoint, requestBody);

      if (response.data.message === 'Reset Password Success') {
        Swal.fire({
          title: 'Berhasil!',
          text: 'Password berhasil direset.',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK',
        }).then(() => {
          window.location.href = '/';
        });
      } else {
        Swal.fire({
          title: 'Kesalahan',
          text: 'Terjadi kesalahan saat mereset password.',
          icon: 'error',
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Kesalahan',
        text: error.response?.data?.message || 'Terjadi kesalahan. Silakan coba lagi nanti.',
        icon: 'error',
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 via-lightblue-500 to-red-500">
      <div className="w-96 bg-white p-6 rounded shadow-md relative">
        {/* Ikon Panah Kembali */}
        <button
          onClick={() => (window.location.href = '/')}
          className="absolute top-4 left-4 text-gray-700 hover:text-blue-500 focus:outline-none"
        >
          <FaArrowLeft size={20} /> {/* Icon Font Awesome */}
        </button>

        <h2 className="text-2xl font-bold mb-4 text-center">Reset Password</h2>
        <form onSubmit={handleResetPassword}>
          <div className="mb-4">
            <label htmlFor="userID" className="block text-sm font-medium text-gray-700">
              UserID
            </label>
            <input
              type="text"
              id="userID"
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Masukkan UserID Anda"
              value={userID}
              onChange={(e) => setUserID(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="uniqueCode" className="block text-sm font-medium text-gray-700">
              Kode Unik
            </label>
            <input
              type="text"
              id="uniqueCode"
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Masukkan kode unik Anda"
              value={uniqueCode}
              onChange={(e) => setUniqueCode(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
              Kata Sandi Baru
            </label>
            <input
              type="password"
              id="newPassword"
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Masukkan kata sandi baru Anda"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className={`${
              loading ? 'bg-gray-400' : 'bg-blue-500'
            } text-white px-4 py-2 rounded w-full font-medium focus:outline-none focus:ring-2 focus:ring-blue-500`}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Reset Password'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
