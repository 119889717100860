import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../components/Layout';
import { AiOutlineSetting } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { selectBaseURL } from '../store/url/urlSlice';
import { useSelector } from 'react-redux';
const AreaViewMenu = () => {
  const [areas, setAreas] = useState([]);
  const baseURL = useSelector(selectBaseURL);
  useEffect(() => {
    fetchAreas();
  }, []);

  const fetchAreas = async () => {
    try {
      const response = await axios.get(`${baseURL}/geoapi/area/varea`);
      setAreas(response.data);
    } catch (error) {
      console.error('Error fetching areas:', error);
    }
  };

  return (
    <Layout>
      <div className="p-4">
      <div className="flex justify-left mt-4">
          <Link
            to="/newarea"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <AiOutlineSetting className="mr-2" /> Create New Area
          </Link>
        </div>
        <br></br>
        <h1 className="text-xl dark:text-white font-semibold mb-4">List of Areas</h1>
        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {areas.map((area) => (
            <li key={area.id} className="bg-white-300 dark:bg-gray-600 p-4 rounded-lg shadow-md">
              <h2 className="text-lg font-semibold dark:text-white mb-2">{area.name}</h2>
              <p className="dark:text-white">Area id: {area.area_id}</p>
              <p className="dark:text-white">Latitude: {area.latitude}</p>
              <p className="dark:text-white">Longitude: {area.longitude}</p>
              <p className="dark:text-white">Radius: {area.radius} km</p>
              <p className="dark:text-white">Sudin: {area.sudin}</p>
              <p className="dark:text-white">Kelurahan: {area.kelurahan}</p>
              <p className="dark:text-white">Kecamatan: {area.kecamatan}</p>
              <p className="dark:text-white">Alamat: {area.alamat}</p>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  );
};

export default AreaViewMenu;
