import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { SiOpenaccess, SiGooglemaps } from 'react-icons/si';
import { CgProfile, CgMathPlus, CgUserList } from 'react-icons/cg';
import Logo from '../assets/images/logo.png';

const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const userID = localStorage.getItem('IDs');
  const Role = localStorage.getItem('Role');

  // console.log(userID, Role);

  const handleLogout = () => {
    Swal.fire({
      title: 'Konfirmasi Logout',
      text: 'Anda yakin akan logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Logout',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('token');
        localStorage.removeItem('userID');
        Swal.fire({
          title: 'Logout Berhasil',
          text: 'Anda telah berhasil logout.',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK',
        }).then(() => {
          window.location.href = '/';
        });
      }
    });
  };

  const location = useLocation();

  const Menus = [
    { title: 'Register', path: '/Register', icon: <CgMathPlus />, roles: ['Admin', 'Master'] },
    { title: 'MapGeo', path: '/MapWithGeolocation', icon: <SiGooglemaps />, roles: ['Super Admin', 'Admin', 'Master'] },
    { title: 'MapTije', path: '/MapTransjakarta', icon: <SiGooglemaps />, roles: ['Super Admin', 'Master'] },
    { title: 'Profile', path: `/profile/${userID}`, icon: <CgProfile />, roles: ['Super Admin', 'Admin', 'Master'] },
    { title: 'User List', path: '/ViewAllUsers', icon: <CgUserList />, roles: ['Super Admin', 'Admin', 'Master'] },
  ];

  return (
    <div className={`${open ? 'w-60' : 'w-fit'} hidden sm:block relative h-screen duration-300 bg-gray-100 border-r dark:bg-gray-800 p-1`}>
      <BsArrowLeftCircle
        className={`${!open && 'rotate-180'} absolute text-3xl bg-white fill-slate-800 rounded-full cursor-pointer top-9 -right-4 dark:fill-gray-400 dark:bg-gray-800`}
        onClick={() => setOpen(!open)}
      />
      <Link to='/HomePage'>
        <div className={`flex ${open && 'gap-x-4'} items-center`}>
          <img src={Logo} alt='GeoMap Logo' className='pl-2' style={{ width: '55px', height: 'auto' }} />
          {open && (
            <span className='text-xl font-medium whitespace-nowrap dark:text-white'>
              GeoMap
            </span>
          )}
        </div>
      </Link>
      <ul className='pt-6'>
        {Menus.filter(menu => menu.roles.includes(Role)).map((menu, index) => (
          <Link to={menu.path} key={index}>
            <li
              className={`flex items-center gap-x-6 p-3 text-base font-normal rounded-lg cursor-pointer dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700 ${menu.gap ? 'mt-9' : 'mt-2'} ${location.pathname === menu.path && 'bg-gray-200 dark:bg-gray-700'}`}
            >
              <span className='text-2xl'>{menu.icon}</span>
              <span className={`${!open && 'hidden'} origin-left duration-300 hover:block`}>
                {menu.title}
              </span>
            </li>
          </Link>
        ))}
      </ul>
      <ul>
        <li className="py-2">
          <div onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="cursor-pointer flex items-center dark:text-white hover:text-gray-300">
            <span className="mr-2">
              <SiOpenaccess />
            </span>
            Logout
          </div>
          {isDropdownOpen && (
            <div className="ml-4 mt-2 space-y-2">
              <button onClick={handleLogout} className="dark:text-white hover:text-gray-300">
                Logout Now
              </button>
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
